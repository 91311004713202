<template>
    <div class="main-container">
      <!-- BANNER -->
      <div class="banner">
                    <Particles
                id="tsparticles"
                :options="{             
                    fpsLimit: 60,
                    interactivity: {
                        detectsOn: 'window',
                        events: {
                            onClick: {
                                enable: true,
                                mode: 'repulse'
                            },
                            onHover: {
                                enable: true,
                                mode: 'grab'
                            },
                            resize: true
                        },
                        modes: {
                            bubble: {
                                distance: 400,
                                duration: 2,
                                opacity: 0.8,
                                size: 40
                            },
                            push: {
                                quantity: 4
                            },
                            repulse: {
                                distance: 200,
                                duration: 0.4
                            },
                            grab: {
                              distance: 200,
                              line_linked: {
                                opacity: 0.2
                              }
                            }
                        }
                    },
                    particles: {
                        color: {
                            value: '#ff8b8b'
                        },
                        links: {
                            color: '#ff8b8b',
                            distance: 150,
                            enable: true,
                            opacity: 0.2,
                            width: 1
                        },
                        collisions: {
                            enable: true
                        },
                        move: {
                            direction: 'none',
                            enable: true,
                            outMode: 'bounce',
                            random: false,
                            speed: 1,
                            straight: false
                        },
                        number: {
                            density: {
                                enable: true,
                                value_area: 800
                            },
                            value: 20
                        },
                        opacity: {
                            value: 0.2
                        },
                        shape: {
                            type: 'circle'
                        },
                        size: {
                            random: true,
                            value: 5
                        }
                    },
                    detectRetina: true
                }"
        />
        <div class="banner-container">
          <div class="banner-col">
            <h1>
                THE <strong>1<sup>ST</sup> INTERACTIVE & VIRTUAL EVENT</strong><br />
                DEDICATED TO<br />
                <strong>BLOCKCHAIN & CRYPTO ENTHUSIAST</strong><br />
                ACROSS THE GLOBE
            </h1>
            <div class="date-container">
              <div class="date-content">May 23-25, 2024<br /><span>2nd Edition - FREE Entrance</span></div>
              <div class="date-event">VIRTUAL<br />EVENT</div>
              <div class="date-event-resp">VIRTUAL EVENT</div>
            </div>
             <div class="btn" @click="displayVideo = true">WATCH THE TRAILER</div>
          </div>
          <div class="banner-col">
            <div class="banner-item">
              <h2>Interact with leading crypto<br /> <span>&</span> blockchain projects</h2>
            </div>
            <div class="banner-item">
              <h2>Network with experts, developers, investors<br /><span>&</span> key industry players</h2>
            </div>
            <div class="banner-item">
              <h2>Raise funds for your project <br /><span>&</span>  innovation</h2>
            </div>
            <div class="banner-item">
              <h2>Hear experts, investors <span>&</span> founders <br /> discuss the latest trends in blockchain technology</h2>
            </div>
          </div>
        </div>
        <div class="banner-bar">
          <p>Blockchain <span>•</span> Crypto <span>•</span> DeFi <span>•</span> GameFi <span>•</span> Metaverse <span>•</span> NFT <span>•</span> Web 3.0</p>
        </div>
      </div>

      <!-- SECTION : PARTICIPATE -->
      <section class="section-participate">
          <h2>Advantages of Participating in this Virtual Event</h2>
          <div class="section-box">
            <div class="particpate-container">
              <div class="participate-item" v-for="(item, idx) in participate" :key="idx">
                <div class="participate-puce"></div>
                <p class="participate-title" v-html="item.title"></p>
                <p class="participate-text" v-html="item.text"></p>
              </div>
            </div>
          </div>
      </section>

      <!-- SECTION EXHIBIT -->
      <section class="section-exhibit">
        <div class="exhibit-sep-top"></div>
        <h2>Why Become an Exhibitor?</h2>
        <div class="exhibit-container">
          <div class="exhibit-item" v-for="(item, idx) in exhibit1" :key="idx">
            <h3 v-html="item.title"></h3>
            <p v-html="item.text"></p>
          </div>
        </div>
        <div class="btn-container">
          <router-link to="/exhibitors" class="exhibit-btn">Become an Exhibitor</router-link>
        </div>
        <h2>Why Become an Attendee?</h2>
        <div class="exhibit-container">
          <div class="exhibit-item" v-for="(item, idx) in exhibit2" :key="idx">
            <h3 v-html="item.title"></h3>
            <p v-html="item.text"></p>
          </div>
        </div>
        <div class="btn-container">
          <router-link to="/visitors" class="exhibit-btn">Attend the Event</router-link>
        </div>
        <div class="exhibit-sep-bottom"></div>
      </section>


      <!-- SECTION EXHIBITORS -->
      <section class="section-sponsors">
        <exhibitors-list
            title="<span class='grey'>Our</span> Exhibitors"
            type="2"
        >
        </exhibitors-list>
      </section>

      <!-- SECTION HALL -->
      <section class="section-default section-hall">
        <div class="section-box">
          <div class="col-50">
            <img class="section-img" alt="hall" src="./../assets/hall.png" />
          </div>
          <div class="col-50">
            <div class="text-container">
              <h2>Exhibit Hall</h2>
              <p>Visit the 3D booth of the leading <strong>crypto</strong> & <strong>blockchain</strong> companies who are showcasing their latest <strong>products, services, tools, softwares & innovations</strong>. </p>
              <p>Book a meeting with one of their <strong>CEO</strong>, <strong>manager</strong> or <strong>representative</strong>.</p>
            </div>
          </div>
        </div>
      </section>

      <!-- SECTION NETWORKING -->
      <section class="section-default section-networking reverse">
        <div class="section-box">
          <div class="col-50">
            <img class="section-img" alt="hall" src="./../assets/networking.png" />
          </div>
          <div class="col-50">
            <div class="text-container">
              <h2>Networking</h2>
              <p>Meet the world-renowned <strong>industry experts</strong> and <strong>investors</strong> & gain insights from these <strong>IT Leaders</strong>, <strong>Tech Entrepreneurs</strong> and <strong>Blockchain Developers</strong>.</p>
            </div>
          </div>
        </div>
      </section>

      <!-- SECTION AUDITORIUM -->
      <section class="section-default section-auditorium">
        <div class="section-box">
          <div class="col-50">
            <img class="section-img" alt="hall" src="./../assets/auditorium.png" />
          </div>
          <div class="col-50">
            <div class="text-container">
              <h2>Conferences<br /> Auditorium</h2>
              <p>Listen to clarifying sessions & <strong>enriching keynotes</strong> from some of the greatest minds in the <strong>blockchain</strong> and <strong>crypto industry space</strong>. </p>
            </div>
          </div>
        </div>
      </section>

      <!-- SECTION CONFS -->
      <section class="section-default section-confs reverse">
        <div class="section-box">
          <div class="col-50">
            <img class="section-img" alt="hall" src="./../assets/confs.png" />
          </div>
          <div class="col-50">
            <div class="text-container">
              <h2>Technical Sessions<br /> Auditorium</h2>
              <p>Attend to workshops and <strong>educational</strong> sessions and/or listen to <strong>presentations</strong>, <strong>solutions demonstrations</strong>, pitches and use-case-studies from the exhibitors.</p>
            </div>
          </div>
        </div>
      </section>

      <!-- SECTION SPONSORS -->
      <section class="section-sponsors">
        <!-- <div class="our-sponsors-container">
          <h2>Our <span class="title-primary">sponsors</span> </h2>
          <div class="logos-container">
            <div class="logo-item sponsors-item" v-for="(item, idx) in sponsors" :key="idx">
              <a :href="item.url" target="_blank">
                <img :src="item.img" :alt="item.alt" />
              </a>
            </div>
          </div>
          <div class="btn-container">
            <router-link to="/sponsors" class="actions-btn sponsors-btn">Become a sponsor</router-link>
          </div>
        </div> -->
        <!-- <div class="our-exhibitor-container">
          <h2>Our <span class="title-primary">exhibitors</span> </h2>
          <div class="logos-container">
            <div class="logo-item" v-for="(item, idx) in exhibitors" :key="idx">
              <a :href="item.url" target="_blank">
                <img :src="item.img" :alt="item.alt" />
              </a>
            </div>
          </div>
          <div class="btn-container">
            <router-link to="/exhibitors" class="actions-btn sponsors-btn">Become an exhibitor</router-link>
          </div>
        </div> -->
        <div class="actions-container">
          <div class="actions-col">
            <h3>REGISTER FOR THE EVENT</h3>
            <a  target="_blank" href="https://cryptovirtua.my-virtual-convention.com/register" class="actions-btn" >Register</a>
          </div>
          <div class="actions-col">
            <h3>ALREADY HAVE AN ACCOUNT?</h3>
            <a  target="_blank" href="https://cryptovirtua.my-virtual-convention.com/login" class="actions-btn actions-login" >Log in</a>
          </div>
        </div>
      </section>
      <!-- SECTION ORGANIZED -->
      <section class="section-organized">
        <h2>Organized by</h2>
        <div class="partners-container">
          <div class="partners-col">
            <a href="https://www.synthes3d.com/en/" target="_blank">
              <img src="./../assets/logo-synthes3d-white.png" alt="logo Synthes3d" />
            </a>
          </div>    
        </div>
      </section>
      <!-- SECTION PARTNERS -->
      <!-- <section class="section-partners">
        <h2>Partners</h2>
        <div class="partners-container">
          <div class="partners-col">
            <a href="https://www.synthes3d.com/en/" target="_blank">
              <img src="./../assets/logo-synthes3d.png" alt="logo Synthes3d" />
            </a>
          </div>
          <div class="partners-col">
            <a href="https://medtechmomentum.com/" target="_blank">
              <img src="./../assets/logo-medtech.png" alt="logo medTech" />
            </a>
          </div>
        </div>
      </section> -->
    <video-modal 
      v-if="displayVideo"
      v-on:close="closeVideo"  
      type="home"
    >
    </video-modal>
    </div>
</template>

<script>

import VideoModal from "./../components/VideoModal.vue";
import ExhibitorsList from "./../components/ExhibitorsList.vue";

export default {
  name: 'Home',
  metaInfo: {
    title: 'CryptoVirtua',
    titleTemplate: null,
    meta: [
      { name: 'description', content: 'Blockchain - Crypto - DeFi - GameFi - Metaverse - NFT - Web 3.0' },
    ]
  },
  components : {
    VideoModal,
    ExhibitorsList
  },
  data() {
    return {
      participate : [
        {
          title : 'High volume of attendees',
          text : 'Blockchain professionals and industry key opinion leaders'
        },
        {
          title : 'Affordable',
          text : 'Cost of virtual event is 75% less expensive than in-person event'
        },
        {
          title : 'Sales productivity',
          text : 'No time lost in travelling to remote event, so more time with customers'
        },
        {
          title : 'Branding',
          text : 'New opportunity for virtual marketing to potential customers'
        },
        {
          title : 'Secure communication platform',
          text : 'Interact with potential customers and partners'
        },
        {
          title : 'Key thought leaders',
          text : 'Leading edge insights and possible partnership opportunities'
        },
        {
          title : 'Web-based communication program',
          text : 'Increased visibility and search-engine optimization'
        },
        {
          title : '"Green Tech" initiative',
          text : 'No travel required, low carbon footprint'
        },
      ],
      exhibit1 : [
        {
          title : 'Promote',
          text : 'your products and services'
        },
        {
          title : 'Increase',
          text : 'your company’s notoriety and visibility'
        },
        {
          title : 'Unveil',
          text : 'your latest innovations'
        },
      ],
      exhibit2 : [
        {
          title : 'Discover',
          text : 'the key players in the Blockchain industry and interact with exhibitors'
        },
        {
          title : 'Chat',
          text : 'with them via the Live Chat'
        },
        {
          title : 'Make',
          text : 'an appointment with  their sales team'
        },
      ],
      sponsors : [
        {
          img : require('@/assets/sponsors/synthes3d.png'),
          alt : 'Logo SYNTHES3D',
          url : 'https://www.synthes3d.com/en/'
        },
        // {
        //   img : require('@/assets/sponsors/OTW.png'),
        //   alt : 'Logo OTW',
        //   url : 'https://ryortho.com/'
        // },
        {
          img : require('@/assets/sponsors/logo-medtech.png'),
          alt : 'Logo MedTech',
          url : 'https://medtechmomentum.com/'
        },
      ],
      exhibitors : [
        {
          img : require('@/assets/sponsors/logo-medtech.png'),
          alt : 'Logo MedTech',
          url : 'https://medtechmomentum.com/'
        },
        {
          img : require('@/assets/sponsors/logo-medtech.png'),
          alt : 'Logo MedTech',
          url : 'https://medtechmomentum.com/'
        },
        {
          img : require('@/assets/sponsors/logo-medtech.png'),
          alt : 'Logo MedTech',
          url : 'https://medtechmomentum.com/'
        },
        {
          img : require('@/assets/sponsors/logo-medtech.png'),
          alt : 'Logo MedTech',
          url : 'https://medtechmomentum.com/'
        },
        {
          img : require('@/assets/sponsors/logo-medtech.png'),
          alt : 'Logo MedTech',
          url : 'https://medtechmomentum.com/'
        },
        {
          img : require('@/assets/sponsors/logo-medtech.png'),
          alt : 'Logo MedTech',
          url : 'https://medtechmomentum.com/'
        },
        {
          img : require('@/assets/sponsors/logo-medtech.png'),
          alt : 'Logo MedTech',
          url : 'https://medtechmomentum.com/'
        },
      ],
      displayVideo :  false
    }
  },
  methods : {
    closeVideo() {
      this.displayVideo = false;
    }
  }
}
</script>

<style lang="scss" scoped>
  .banner {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url('./../assets/banner-bg.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position:relative;
  }

  .banner-bar {
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    background:$main-color;
    display: flex;
    justify-content: center;
  }

  .banner-bar p {
    margin:20px;
    color:#fff;
    text-transform: uppercase;
    font-size:30px;
    letter-spacing: 2px;
  }

  .banner-bar p span {
    color:$text-color;
  }

  .banner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: $box;
    max-width: 96%;
  }

  .banner-col {
    width:49%;
    margin:0 0.5%;
    display: flex;
    flex-direction:column;
    align-items: flex-start;
  }

  .banner-col:last-child {
    align-items: flex-end;
  }

  .banner h1 {
    color:#fff;
    font-size:28px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }

  .banner h1 span {
    color:$main-color;
  }

  .banner h1 sup {
    font-size:0.7em;
  }

  .date-container {
    display: flex;
    align-items: center;
    margin-top:40px;
    margin-bottom:40px;
  }

  .date-content {
    color:$main-color;
    font-size:40px;
    font-weight: 700;
    margin-right:30px;
  }

  .date-content span {
    font-weight:400;
    font-size:25px;
  }

  .date-event {
    color:#fff;
    font-size:30px;
    font-weight: 700;
    padding: 5px 0 5px 30px;
    border-left:2px solid $main-color
  }

  .date-event-resp {
    display:none;
  }

  .banner-item {
    margin-top:30px;
    margin-bottom:30px;
    padding-right:20px;
    border-right:5px solid $main-color;
  }

  .banner-item h2 {
    text-align: right;
    color:#fff;
    font-size:20px;
    font-weight:400;
    text-transform: uppercase;
    margin:0;
  }

  .banner-item h2 span {
    color:$main-color;
  }


  /* END BANNER */

  .section-participate {
    padding:80px 0;
    background-image: url('./../assets/bg-home-participate.png');
    background-repeat: no-repeat;
    background-position: center right;
  }

  .particpate-container {
    width:90%;
    margin: 0 auto;
  }

  .row {
    width:100%;
    display: flex;
    align-items: center;
    margin-top : 80px;
  }

  .col-50 {
    width:49%;
    margin:0 auto;
  }

  .video {
    max-width: 100%;
  }

  /* participate */

  .participate-item {
    display: flex;
    justify-content: flex-start;
    margin-top:30px;
    margin-bottom:30px;
  }

  .participate-puce{
    height:20px;
    width:23px;
    min-width: 23px;
    background-image: url('./../assets/puce.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0 20px 0 40px;
  }

  .participate-title {
    margin: 0;
    width:300px;
    min-width: 200px;
    color:$main-color;
    font-weight:700;
  }

  .participate-text {
    color:$text-color;
    margin:0;
  }

  /* Exhibit */

  .section-exhibit {
    padding:180px 0;
    background-image: url('./../assets/exhibit-bg.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }

  .exhibit-container {
    display: flex;
    justify-content: center;
    margin-bottom:20px;
    flex-wrap: wrap;
  }

  .exhibit-item {
    height:120px;
    width:220px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border:1px solid #fff;
    border-radius:10px;
    margin: 20px 40px;
    padding:0 40px;
  }

  .main-container .section-exhibit h2 {
    color:$secondary-color;
  }

  .exhibit-item h3 {
    margin:0;
    padding:0;
    color:$secondary-color;
    font-weight:700;
  }

  .exhibit-item p {
    margin:0;
    padding:0;
    color:#fff;
    font-weight:500;
    text-align: center;
  }

  .btn-container {
    display: flex;
    justify-content: center;
    width:100%;
    margin-bottom:80px;
  }

  .exhibit-btn {
    padding:0 20px;
    height:40px;
    background-color: #fff;
    border:1px solid #fff;
    border-radius:20px;
    color:$secondary-color;
    font-size:20px;
    font-weight:700;
    text-decoration: none;
    transition: 0.5s;
    display: flex;
    align-items: center;
  }

  .exhibit-btn:hover {
    border:1px solid $secondary-color;
    background:$secondary-color;
    color:#fff;
  }

  //hide empty exhibitors
  .our-exhibitor-container {
    display: none;
  }
 
  /* SECTION SPONSORS */
  .section-sponsors {
    padding:80px 0;
    background-image: url('./../assets/bg-home-sponsors.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .section-box {
    width:1400px;
    max-width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .reverse .section-box {
    flex-direction: row-reverse;
  }


  /* ORGANIZED */
  .section-organized {
    background-image: url('./../assets/organized-bg.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding:80px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .section-organized h2 {
    color:#fff;
  }

  .section-organized img {
    width:400px;
    height:auto;
    margin:-20px auto 0 auto;
    max-width: 90%;
  }

  /* PARTNERS */
  .section-partners {
    padding:80px 0;
  }

  .partners-container {
    width: $box;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 90%;
  }

  .partners-col {
    width:20%;
    display: flex;
    justify-content: center;
  }

  .partners-col a {
    display: flex;
  }

  .partners-col img {
    width:300px;
    height:auto;
    max-width: 80%;
  }

  .title-primary {
    color:$main-color;
  }

  .btn {
    margin:0 5px;
    height:40px;
    width:300px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius:20px;
    cursor: pointer;
    transition:0.5s;
    font-size:18px;
    text-decoration: none;
    background:$main-color;
    margin-top:40px;
    border:1px solid $main-color;
  }

  .btn:hover {
    background:$secondary-color;
    border:1px solid $secondary-color;
  }

  .actions-container {
    margin-top:80px;
  }

  /* DEFAULT */
  .section-default {
    display:flex;
    align-items: center;
    flex-direction: row;
    padding: 80px 0; 
  }

  .section-default.reverse  {
    flex-direction: row-reverse;
  }

  .section-default .text-container {
    width:500px;
    max-width: 90%;
    margin:0 auto;
  }

  .section-default .col-50 {
    width:49%;
    margin: 0 0.5%;
  }

   .section-default h2 {
     font-size:45px;
     font-weight:700;
     letter-spacing: 2px;
     color:#fff;
   }

   .section-default p {
     color:#fff;
     line-height: 2.5em;
   }

   .section-default img {
     max-width: 90%;
   }

  /* HALL */

  .section-hall {
    // background-image: url('./../assets/hall-bg.jpg');
    // background-position: center right;
    // background-repeat: no-repeat;
    // background-size: cover;
    background: linear-gradient(#0d0c52, #181772);
    min-height:350px;
  }

  /* NETWORKING */

  .section-networking {
    // background-image: url('./../assets/networking-bg.jpg');
    // background-position: center left;
    // background-repeat: no-repeat;
    // background-size: cover;
    background: linear-gradient(#ee8b8b, #f7a2a2);
    min-height:350px;
  }

  /* AUDITORIUM */

  .section-auditorium {
    // background-image: url('./../assets/auditorium-bg.jpg');
    // background-position: center right;
    // background-repeat: no-repeat;
    // background-size: cover;
    background: linear-gradient(#361d68, #432974);
     min-height:350px;
  }

  /* CONFS */

  .section-confs {
    // background-image: url('./../assets/confs-bg.jpg');
    // background-position: center left;
    // background-repeat: no-repeat;
    // background-size: cover;
    background: linear-gradient(#b92e5c, #de4376);
     min-height:350px;
  }

  @media screen and (max-width:1100px) {
    
    .partners-container {
      flex-direction: column;
    }

    .partners-col {
      width:100%;
      margin-top:20px;
      margin-bottom:20px;
    }

    .row {
      flex-direction: column;
    }

    .col-50,  .section-default .col-50  {
      width:90%;
    }

    .section-default, .section-default.reverse {
      flex-direction: column;
    }



    .section-default img {
      margin-bottom:20px;
    }

    .section-default .col-50 {
      display: flex;
      justify-content: center;
    }

    .banner-col:last-child {
      display: none;
    }

    .banner-col {
      width:98%;
      margin:0 1%;
      align-items: center;
    }

   
  }

  @media screen and (max-width:768px) {
    .section-sponsors, .section-participate, .section-exhibit {
      padding:40px 0;
    }

    .particpate-container {
      margin-top:40px;
    }

    .banner-container {
      margin-top:80px;
    }

    .banner h1 {
      font-size:25px;
      text-align: center;
    }

    .banner h2 {
      font-size:20px;
    }

    .banner {
      min-height:auto;
    }


    .date-container {
      flex-direction: column;
      align-items: center;
      width:98%;
      margin: 20px 1%;
    }

    .date-content {
      font-size:30px;
      margin-right: 0;
    }

    .date-event-resp {
      font-size:25px;
      border:none;
      padding: 0;
      margin-top:10px;
      color:#fff;
      font-weight: 700;
      display: flex;
    }

    .date-event {
      display: none;
    }

    .banner-bar {
      position:relative
    }

    .banner-bar p {
      text-align: center;
      font-size:20px;
    }

    .btn {
      margin-top:20px;
      margin-bottom:40px;
    }

    .section-default h2 {
      font-size:25px;
    }

    .section-box, .reverse .section-box {
      flex-direction: column;
      align-items: center;
    }

    .section-box .col-50 {
      width:98%;
    }
  }
  


</style>
