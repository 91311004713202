<template>
    <footer>
        <div class="row">
            <div class="col-33">
                <img src="./../assets/logo-footer.png" alt="logo ortho" class="logo-footer" />

            </div>
            <div class="col-33">
                <div class="contact-list">
                    <h3>A virtual event organized by:</h3>
                    <div class="contact-item">
                        <div class="contact-icon address"></div>
                        <p>
                            <strong>SYNTHES'3D</strong><br />
                            4, Rue Mahatma Gandhi<br />
                            Espace Beauvalle, Bât. B<br />
                            13 090 Aix-en-Provence<br />
                            France
                        </p>
                    </div>
                    <div class="contact-item">
                        <div class="contact-icon mail"></div>
                        <p>
                            <a href="mailto:contact@synthes3d.com">contact@synthes3d.com</a>
                        </p>
                    </div>
                    <div class="contact-item">
                        <div class="contact-icon phone"></div>
                        <p>+33 (0)9 70 66 97 04</p>
                    </div>
   
     
                </div>
            </div>
            <div class="col-33">
                <img src="./../assets/btn-ether.png" alt="ethernum" class="currency" />
                <img src="./../assets/btn-bitcon.png" alt="bitcoin" class="currency" />
            </div>
        </div>
        <div class="rs-container">
            <a href="https://www.linkedin.com/company/cryptovirtua" target="_blank" class="rs-item linkedin"></a>
            <a href="https://www.facebook.com/profile.php?id=100076855104312" target="_blank" class="rs-item facebook"></a>
            <a href="https://twitter.com/CryptoVirtua" target="_blank" class="rs-item twitter"></a>
            <a href="https://www.instagram.com/cryptovirtua/" target="_blank"  class="rs-item instagram"></a>            
            <a href="https://www.youtube.com/channel/UCSitxFCjANDXVLWC_cAyoYA" target="_blank" class="rs-item youtube"></a>
        </div>        
        <div class="footer-content">
            <p>© Copyright {{ currentYear }} - <a href="https://www.synthes3d.com/en/legal-notices/" target="_blank">Legal notices</a> -  CRYPTOVIRTUA by <a href="https://www.synthes3d.com/en/" target="_blank">SYNTHES'3D</a></p>
        </div>
    </footer>
</template>

<script>
export default {
    name :'FooterApp',
    data() {
        return {
            currentYear : new Date().getFullYear()
        }
    }
}
</script>

<style lang="scss" scoped>
    footer {
        width: 100%;
        background-color: $grey;
    }

    .footer-sep {
        width: 100%;
        height:120px;
        background-image: url('./../assets/sep-footer.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .footer-content {
        border-top:1px solid #fff;
    }

    .footer-content p {
        color:$secondary-color;
        text-align:center;
        margin:0 auto;
        padding:20px;
        font-weight:300;
        font-size:15px;
    }

    .footer-content p a {
        color:$secondary-color;
        text-decoration: none;
        cursor: pointer;
    }

    .footer-content p a:hover {
        text-decoration: underline;
    }

    .footer-focus {
        color:$main-color;
    }

    .logo-footer {
        max-width: 350px;
    }

    .row {
        display: flex;
        width:$box;
        max-width: 90%;
        margin:0 auto;
        padding:40px 0;
        align-items: center;
    }

    .col-33 {
        width:31%;
        margin:1% auto;
        display: flex;
        flex-direction: column;
        align-items: center;

    }
    .contact-item {
        display: flex;
        align-items: center;
        margin-top:10px;
    }

    .logo-footer {
        width:292px;
        height:auto;
    }

    .contact-item p {
        font-size:15px;
        color:$secondary-color;
        margin:0;
    }

    .currency {
        width:250px;
        margin:10px 0;
    }

    .contact-item p a {
        text-decoration: none;
        color:$secondary-color;
    }

    .contact-item p a:hover {
        text-decoration: underline;
    }

    .contact-icon {
        width:35px;
        min-width: 35px;
        height:35px;
        margin-right:10px;
    }

    .contact-icon.phone {
        background-image: url('./../assets/icon-phone.png');
        background-position: center;
        background-size: 35px;
        background-repeat: no-repeat;
    }

    .contact-icon.mail {
        background-image: url('./../assets/icon-mail.png');
        background-position: center;
        background-size: 35px;
        background-repeat: no-repeat;
    }

    .contact-icon.address {
        background-image: url('./../assets/icon-address.png');
        background-position: center;
        background-size: 35px;
        background-repeat: no-repeat;
    }

    .press-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    h3 {
        color:$secondary-color;
        font-weight:600;
        font-size:18px;
        margin:0 0 10px 0;
        padding-bottom: 10px;
    }

    .press {
        margin-top:40px;
        width:200px;
        max-width: 90%;
        height:auto;
        cursor: pointer;
    }

    .rs-container {
        display: flex;
        justify-content: center;
        width:$box;
        max-width: 90%;
        margin:10px auto;
    }

    .rs-item {
        width:35px;
        min-width: 35px;
        height:35px;
        margin:0 10px;
        cursor: pointer;
        transition: 0.5s;
    }

    .rs-item.facebook {
        background-image: url('./../assets/icon-facebook.png');
        background-position: center;
        background-size: 35px;
        background-repeat: no-repeat; 
    }

    .rs-item.facebook:hover {
        background-image: url('./../assets/icon-facebook-o.png');
        background-position: center;
        background-size: 35px;
        background-repeat: no-repeat; 
    }

    .rs-item.youtube {
        background-image: url('./../assets/icon-youtube.png');
        background-position: center;
        background-size: 35px;
        background-repeat: no-repeat; 
    }

    
    .rs-item.youtube:hover {
        background-image: url('./../assets/icon-youtube-o.png');
        background-position: center;
        background-size: 35px;
        background-repeat: no-repeat; 
    }

    .rs-item.instagram {
        background-image: url('./../assets/icon-instagram.png');
        background-position: center;
        background-size: 35px;
        background-repeat: no-repeat; 
    }

    .rs-item.instagram:hover {
        background-image: url('./../assets/icon-instagram-o.png');
        background-position: center;
        background-size: 35px;
        background-repeat: no-repeat; 
    }

    .rs-item.twitter {
        background-image: url('./../assets/icon-twitter.png');
        background-position: center;
        background-size: 35px;
        background-repeat: no-repeat; 
    }

    .rs-item.twitter:hover {
        background-image: url('./../assets/icon-twitter-o.png');
        background-position: center;
        background-size: 35px;
        background-repeat: no-repeat; 
    }

    .rs-item.linkedin {
        background-image: url('./../assets/icon-linkedin.png');
        background-position: center;
        background-size: 35px;
        background-repeat: no-repeat; 
    }

    .rs-item.linkedin:hover {
        background-image: url('./../assets/icon-linkedin-o.png');
        background-position: center;
        background-size: 35px;
        background-repeat: no-repeat; 
    }

    @media screen and (max-width:768px) {
        .footer-content p {
            font-size:13px;
        }

        .footer-sep {
            height:40px;
        }

        .row {
            flex-direction: column;
        }

        .col-33 {
            width:90%;
            margin:20px auto;
        }

        .col-33  img {
            max-width: 90%;
        }
    }
</style>