<template>
    <div class="modal" @click="$emit('close')">
        <div class="close">X</div>
        <div class="modal-container" @click.stop>
            <video class="video" autoplay controls v-if="type == 'home'">
                <source src="./../assets/teaser.mp4" type="video/mp4">
            </video>
               <video class="video" autoplay controls v-if="type == 'exhibitor'">
                <source src="./../assets/exhibitors.mp4" type="video/mp4">
            </video>
        </div>
    </div>
</template>

<script>
export default {
    name : "VideoModal",
    props : ["type"]
}
</script>

<style lang="scss" scoped>
    .modal {
        background:rgba(0,0,0,0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top:0;
        bottom: 0;
        left:0;
        right: 0;
        z-index:999;
    }

    .modal-container {
        max-width: 90%;
        width:1200px;
        display: flex;
        justify-content: center;
    }

    video {
        max-width: 100%;
        width:100%;
    }

    .close {
        position:absolute;
        top:20px;
        right:20px;
        cursor: pointer;
        color: #fff;
        font-size:25px;
        font-weight:700;
    }
</style>