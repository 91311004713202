<template>
    <div :class="['exhibitors-list', getColor()]">
        <h2>They already trust us</h2>
        <div class="logos-container">
            <div class="logo-item" v-for="(item, idx) in exhibitors" :key="idx">
                <a :href="item.url" target="_blank">
                <img :src="item.img" :alt="item.alt" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : "ExhibitorsList",
    props : ['color'],
    data(){
        return  {
            exhibitors : [
                {
                    img : require('@/assets/logos-exhibitors/Logo_BCDiploma_400x230_v2.png'),
                    alt : 'Logo BCDiploma',
                    url : 'https://www.bcdiploma.com/fr'
                },
                {
                    img : require('@/assets/logos-exhibitors/Logo_Bem_Builders_400x230_v2.png'),
                    alt : 'Logo Bem Builders',
                    url : 'https://www.bem.builders/'
                },
                {
                    img : require('@/assets/logos-exhibitors/Logo_Born2Mint_400x230_v2.png'),
                    alt : 'Logo Born2Mint',
                    url : 'https://born2mint.com/'
                },
                // {
                //     img : require('@/assets/logos-exhibitors/Logo_Cara7_400x230_v2.png'),
                //     alt : 'Logo Cara7',
                //     url : 'https://www.cara7.com/'
                // },
                {
                    img : require('@/assets/logos-exhibitors/Logo_coexya_v2.png'),
                    alt : 'Logo coexya',
                    url : 'https://www.coexya.eu/'
                },
                {
                    img : require('@/assets/logos-exhibitors/Logo_Evidenz_400x230_v2.png'),
                    alt : 'Logo Evidenz',
                    url : 'https://www.evidenz.io/'
                }, 
                // {
                //     img : require('@/assets/logos-exhibitors/Logo_France_Innovation_400x230_v2.png'),
                //     alt : 'Logo Innovation',
                //     url : 'https://finance-innovation.org/'
                // },
                {
                    img : require('@/assets/logos-exhibitors/Logo_France_Meta_400x230px_v2.png'),
                    alt : 'Logo France Meta',
                    url : 'https://www.francemeta.org/'
                },
 
                {
                    img : require('@/assets/logos-exhibitors/Logo_Medalverse_400x230_v2.png'),
                    alt : 'Logo Medalverse',
                    url : 'https://medalverse.xyz/'
                },
                {
                    img : require('@/assets/logos-exhibitors/Logo_Nicomatic_400x230px_v1.png'),
                    alt : 'Logo Nicomatic',
                    url : 'https://www.nicomatic.com/fr'
                }, 
                {
                    img : require('@/assets/logos-exhibitors/Logo_Orphen_v2.png'),
                    alt : 'Logo Orphen',
                    url : 'https://orphen-nft.com/'
                },
                {
                    img : require('@/assets/logos-exhibitors/Logo_Palo_IT_400x230_v2.png'),
                    alt : 'Logo Palo IT',
                    url : 'https://www.palo-it.com/fr/'
                },
                {
                    img : require('@/assets/logos-exhibitors/Logo_Quantum_BCS_v2.png'),
                    alt : 'Logo Quantum BCS',
                    url : 'https://quantumbcs.io/en/'
                },
                {
                    img : require('@/assets/logos-exhibitors/Logo_S3D_400x230px_v2.png'),
                    alt : 'Logo SYNTHES3D',
                    url : 'https://www.synthes3d.com/fr/'
                },
                {
                    img : require('@/assets/logos-exhibitors/Logo_Talium_400x230_v2.png'),
                    alt : 'Logo Talium',
                    url : 'https://talium.fr/fr/'
                }     

            ]
        }
    },
    methods: {
        getColor() {
            if(this.color == 2) {
                return 'secondary';
            }
        } 
    }
}
</script>

<style lang="scss" scoped>
    .secondary h2 {
        color:$main-color;
    }

    .logo-item {
        position: relative;
        z-index:9;
    }
</style>