import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import store from './../store/index';
import VueMeta from 'vue-meta';

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: () => import('../views/AboutUs.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/exhibitors',
    name: 'Exhibitors',
    component: () => import('../views/Exhibitors.vue')
  },
  {
    path: '/legal-notices',
    name: 'LegalNotices',
    component: () => import('../views/LegalNotices.vue')
  },
  {
    path: '/sponsors',
    name: 'Sponsors',
    component: () => import('../views/Sponsors.vue')
  },
  {
    path: '/conferences',
    name: 'Conferences',
    component: () => import('../views/Conferences.vue')
  },
  {
    path: '/visitors',
    name: 'Visitors',
    component: () => import('../views/Visitors.vue')
  },
  {
    path: '/speakers',
    name: 'Speakers',
    component: () => import('../views/Speakers.vue')
  },
  {
    path: '/nft',
    name: 'Nft',
    component: () => import('../views/Nft.vue')
  }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to , from, next) => {
  store.state.menu_open = false
  next();
})



export default router
