import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./vee-validate";
import VTooltip from 'v-tooltip';
import Particles from "particles.vue";
import VueAnalytics from 'vue-analytics';

Vue.use(VTooltip);
Vue.use(Particles);

Vue.use(VueAnalytics, {
  id: 'G-F52XRMFPLY',
  router
});


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
